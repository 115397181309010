import { useEffect, useState } from 'react';
import { RoadMap, RoadMapItem } from '@/components/roadMap/item';
import { db } from '@/firebase';
import { query, collection, orderBy, getDocs } from 'firebase/firestore';

export default function RoadmapDetails() {
  const [roadMaps, setRoadMaps] = useState(new Array<any>());

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'road_maps'), orderBy('priority'));
      const querySnapshot = await getDocs(q);

      const results = new Array<any>();
      querySnapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...(doc.data() as any),
        });
      });
      setRoadMaps(results);
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="mx-[10%] py-[10px] lg:py-[60px] grid lg:grid-cols-5 gap-4">
        {roadMaps.map((roadMap) => {
          return <RoadMapItem item={roadMap as RoadMap} key={roadMap.id} />;
        })}
      </div>
    </div>
  );
}
