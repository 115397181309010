import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
// import Newsletter from './newsletter';
import FooterHeader from './header';
import FooterLink from './link';
import { Brand, FooterBrandLink } from './brandLink';

const BRANDS: Array<Brand> = [
  // {
  //   icon: brands('discord'),
  // },
  {
    icon: brands('instagram'),
    url: 'https://www.instagram.com/evolverse.io/',
  },
  // {
  //   icon: brands('reddit'),
  // },
  {
    icon: brands('twitter'),
    url: 'https://twitter.com/teamevolverse',
  },
];

export default function Footer() {
  return (
    <footer className="bottom-0 bg-black text-white overflow-x-hidden">
      <div className="container mx-[20px] lg:mx-auto text-[12.1px] flex divide-x divide-white pb-[40px] lg:pb-[90px] mt-[30px]">
        <div className="pr-[30px] lg:pr-[90px]">
          <div>
            <FooterHeader name="Contact Us" />
            <FooterLink
              name="info@evolverse.io"
              href="mailto:info@evolverse.io"
              external
            />
          </div>
          <div className="hidden lg:block">
            <FooterHeader name="Follow Us" />
          </div>
          <div className="flex">
            {BRANDS.map(function (brand: Brand, i) {
              return <FooterBrandLink brand={brand} key={i} />;
            })}
          </div>
        </div>
        <div className="pl-[30px] lg:pl-[70px]">
          <div className="hidden lg:block">
            <FooterLink name="FAQ" href="/faqs" />
          </div>
          <FooterLink name="Privacy Policy" href="/privacy" />
          <FooterLink name="Terms & Services" href="/terms" />
        </div>
      </div>
      <div className="container mx-[20px] lg:mx-auto text-left text-[12.1px] pb-10 lg:pb-20">
        Copyright EVOLVERSE Limited {new Date().getFullYear()}
      </div>
    </footer>
  );
}
