import PageDescription from '@/components/common/pageDescription';
import PageTitle from '@/components/common/pageTitle';

export default function Garage() {
  return (
    <div className="w-screen aspect-[1660/862] flex justify-center bg-black bg-[url('https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2FNIO.png?alt=media&token=c0dd8502-d384-4313-a670-f345d0d0631f')] bg-cover bg-center">
      <div className="w-[42%] pt-[75.8px] ml-[5%] flex flex-col items-center">
        <PageTitle text="GARAGE" />
        <PageDescription text="Name, decorate, and personalize your own garage. Invite friends and followers over to appreciate your state of the art garage." />
      </div>
      <div className="grow" />
    </div>
  );
}
