export default function PageParagraph({
  text,
  textLeft = false,
}: {
  text: string;
  textLeft?: boolean;
}) {
  return (
    <div
      className={`text-[10px] lg:text-[22px] leading-[13px] lg:leading-[29px] text-white ${
        textLeft ? 'text-left' : 'text-center'
      }`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}
