export interface RoadMap {
  time: string;
  description: string;
  icon?: string;
  background?: string;
  priority: number;
}

export function RoadMapItem({ item }: { item: RoadMap }) {
  return (
    <div
      className={`flex ${
        item.priority % 2 ? 'lg:flex-col' : 'lg:flex-col-reverse'
      } ${
        item.priority % 2 ? 'flex-row' : 'flex-row-reverse'
      } justify-center items-center`}
    >
      <div
        style={{ backgroundImage: `url('${item.background}')` }}
        className={`bg-center bg-contain aspect-[589/783] bg-no-repeat flex ${
          item.priority % 2 ? 'flex-col-reverse' : 'flex-col'
        } rotate-[-90deg] lg:rotate-0`}
      >
        <div className="grow" />
        <div className="w-[100px] lg:w-[150px] xl:w-[200px] aspect-square flex flex-col items-center justify-center rotate-[90deg] lg:rotate-0">
          <img className="h-[20px] lg:h-[50px]" src={item.icon} alt="" />
          <div className="text-white text-md md:text-xl xl:text-3xl">
            {item.time}
          </div>
        </div>
      </div>
      <div
        className={`text-white text-[14px] md:text-[14px] xl:text-[16px] text-left md:leading-[1.8] lg:leading-[2.16] lg:relative grow ${
          item.priority % 2 ? 'ml-5 md:ml-10' : 'mr-5 md:mr-10'
        } lg:mx-0`}
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    </div>
  );
}
