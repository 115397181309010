import * as React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';

import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

import {
  Garage,
  InteractiveGarageItem,
} from '@/components/interactiveGarage/item';

import './styles.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
  },
};

Modal.setAppElement('#root');

export default function GarageGarage() {
  const garages: Array<Garage> = [
    {
      name: 'GOLD',
      image:
        'https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fgarage%2Fgold.jpeg?alt=media&token=e34c209f-3370-4593-8345-caa73a54dc6c',
    },
    {
      name: 'SILVER',
      image:
        'https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fgarage%2Fsilver.jpeg?alt=media&token=60ee8189-81fb-4be2-9ed7-82eb5cfd99b4',
    },
    {
      name: 'BRONZE',
      image:
        'https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fgarage%2Fbronze.jpeg?alt=media&token=2d847494-74b4-4e37-8cf4-b766e9d5eb11',
    },
  ];

  const [modalIsOpen, setIsOpen] = useState(false);
  const [garage, setGarage] = useState({
    name: '',
    image: '',
  });

  const openModal = () => {
    setIsOpen(true);
  };

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const onGarageClick = (i: number) => {
    setGarage(garages[i]);
    openModal();
  };

  return (
    <div className="w-screen aspect-[1660/748] flex flex-col items-center justify-center bg-black bg-cover bg-center my-10">
      <PageSubtitle text="GARAGE" />
      <PageDescription text="Become the envy of the metaverse with a custom garage that reflects your style. Here, you can upgrade, customize, and showcase your dream car collection." />
      <div className="lg:columns-3 mx-[6.5%] mt-[57px]">
        {garages.map(function (garage: Garage, i: number) {
          return (
            <InteractiveGarageItem
              key={i}
              item={garage}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                onGarageClick(i);
                // e.preventDefault();
              }}
            />
          );
        })}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
      >
        <div className="w-[80vw] aspect-[16/9] bg-black">
          <PageSubtitle text={garage.name} />
          <button onClick={closeModal}>close</button>
          <div
            style={{ backgroundImage: `url('${garage.image}')` }}
            className="aspect-[16/9] bg-center bg-contain"
          />
        </div>
      </Modal>
    </div>
  );
}
