import Avator from '@/components/common/avator';

export interface TeamMember {
  id: string;
  position: string;
  name: string;
  profile_image: string;
  description: string;
}

export function TeamMemberItem({ item }: { item: TeamMember }) {
  const { position, name, description, profile_image } = item;
  return (
    <div className="text-white flex py-[10px] lg:py-[34.4px] gap-3">
      <div className="flex flex-col items-center my-2">
        <div>
          <Avator profileImage={profile_image} />
        </div>
        <div className="lg:min-w-[216px] sm:min-w-[160px] text-[10px] sm:text-[18px] lg:text-[30px] text-white text-center">
          {name}
        </div>
        <div className="text-[10px] sm:text-[16px] lg:text-[20px] text-white text-center">
          {position}
        </div>
      </div>
      <div
        className="text-left text-[12px] sm:text-[15px] lg:text-[17.3px] lg:leading-[34.5px] flex flex-col my-3 sm:my-7"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
}
