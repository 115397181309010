import React from 'react';
import PageDescription from '@/components/common/pageDescription';

import GarageGarage from '@/pages/garage/garage';

import MembershipAirdrop from '@/pages/membership/airdrop';
import MembershipNft from '@/pages/membership/nft';
import MembershipEvents from '@/pages/membership/events';
import MembershipPhysicalSouvenirs from '@/pages/membership/physicalSouvenirs';
import GoGarageButton from '@/components/common/goGarageButton';
import ReactPlayer from 'react-player/lazy';

export default function Garage() {
  return (
    <div>
      <div className="w-screen aspect-[1280/720] bg-center bg-cover flex flex-col justify-start md:justify-center relative bg-black">
        <div className="w-[100%] aspect-[1280/720] flex flex-col justify-center items-start absolute z-9">
          <ReactPlayer
            url="https://storage.googleapis.com/evolverse.appspot.com/website_cdn/movies/evolverse_garage_frontpage_v02.mp4"
            playing
            muted
            loop
            playsinline
            controls
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-[100%] z-9">
        <PageDescription text="An evolutionary metaverse for car enthusiasts to own, create, and dazzle." />
        <div className="h-[25%]" />
        <GoGarageButton />
        <div className="h-[15%]" />
      </div>
      <GarageGarage />
      <MembershipEvents />
      <MembershipAirdrop />
      <MembershipNft />
      <MembershipPhysicalSouvenirs />
    </div>
  );
}
