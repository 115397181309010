import GoGarageButton from '@/components/common/goGarageButton';
import PageSubtitle from '@/components/common/pageSubtitle';
import PageDescription from '@/components/common/pageDescription';

export default function Garage() {
  return (
    <div className="w-screen aspect-[1920/1080] bg-center bg-cover flex flex-col justify-end relative bg-[url('https://storage.googleapis.com/evolverse.appspot.com/website_cdn/images/bty_garage_exterior_v03.jpg')]">
      <div className="z-9 text-center mb-[10%] px-10">
        <PageSubtitle text="GARAGE" />
        <div className="h-3 md:h-5 lg:h-7" />
        <PageDescription text="An evolutionary metaverse for car enthusiasts to own, create, and dazzle." />
        <div className="h-4 md:h-10" />
        <GoGarageButton />
      </div>
    </div>
  );
}
