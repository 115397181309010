import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface Brand {
  icon: IconDefinition;
  url?: string;
}

export function FooterBrandLink({ brand }: { brand: Brand }) {
  return (
    <div className="mr-[10px] lg:mr-[20px]">
      <a href={brand.url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon className="fa-2x cursor-pointer" icon={brand.icon} />
      </a>
    </div>
  );
}
