import React from 'react';
import { Link } from 'react-router-dom';

export default function AppButton({
  text,
  href,
}: {
  text: string;
  href?: string;
}) {
  const isExternalLink = href && href.startsWith('http');

  return (
    <button
      type="button"
      className="uppercase text-[13px] mt-2 py-2 px-4 border border-transparent text-sm font-medium text-black bg-white focus:outline-none font-['D_Din_Expand']"
    >
      {!isExternalLink && <Link to={href || ''}>{text}</Link>}
      {isExternalLink && (
        <a href={href || ''} target="_blank" rel="noreferrer">
          {text}
        </a>
      )}
    </button>
  );
}
