import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function Airdrop() {
  return (
    <div className="w-screen aspect-[1920/895] flex bg-black bg-[url('https://storage.googleapis.com/evolverse.appspot.com/website_cdn/images/evolverse_used_cars.jpg')] bg-cover bg-center">
      <div className="flex-1 ml-[10.8%]">
        <div className="flex-1 flex flex-col items-center justify-center">
          <PageSubtitle text="AIRDROPS" />
          <PageDescription text="As an EVOLVERSE member, you're eligible to receive exclusive airdrops of the latest NFT car parts and accessories." />
        </div>
      </div>
      <div className="flex-1 w-[50%] mr-[10.8%] flex flex-col items-center justify-center">
        <div className="flex-1 flex flex-col items-center justify-center"></div>
        <div className="flex-1  flex flex-col items-center justify-end">
          <PageSubtitle text="MINTING PRIORITY" />
          <PageDescription text="Be the first to mint new NFTs." />
          <div className="h-[20%]" />
        </div>
      </div>
    </div>
  );
}
