import AppButton from '@/components/common/appButton';
import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function Partners() {
  return (
    <div className="w-screen aspect-[1660/503] flex flex-col justify-center items-center bg-black">
      <PageSubtitle text="PARTNERS" />
      <div className="h-3 md:h-5 lg:h-7" />
      <PageDescription text="With a shared destination, we're driving side-by-side on the Web3 highway." />
      <div className="h-4 md:h-10" />
      <AppButton text="Check" href="/partners" />
    </div>
  );
}
