import { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy, where } from 'firebase/firestore';

import { db } from '@/firebase';
import { TeamMember, TeamMemberItem } from '@/components/team/memberItem';
import { TeamAdvisor, TeamAdvisorItem } from '@/components/team/advisorItem';
import PageSubtitle from '@/components/common/pageSubtitle';
import PageHeader from '@/components/common/pageHeader';
import PageParagraph from '@/components/common/pageParagraph';

export default function Team() {
  const [members, setMembers] = useState(new Array<any>());
  const [advisors, setAdvisors] = useState(new Array<any>());
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'members'), orderBy('priority'));
      const querySnapshot = await getDocs(q);

      const results = new Array<TeamMember>();
      querySnapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        } as TeamMember);
      });
      setMembers(results);

      const q2 = query(
        collection(db, 'advisors'),
        where('is_hidden', '==', false),
        orderBy('priority')
      );
      const querySnapshot2 = await getDocs(q2);

      const results2 = new Array<TeamAdvisor>();
      querySnapshot2.forEach((doc) => {
        results2.push({
          id: doc.id,
          ...doc.data(),
        } as TeamAdvisor);
      });
      setAdvisors(results2);
    };
    fetchData();
  }, []);
  return (
    <div className="flex justify-center items-center flex-col bg-black pt-[45px] py-[180px]">
      <PageHeader title="GENESIS STORY">
        <>
          <PageParagraph
            text="The EVOLVERSE team is composed of blockchain, animation, and automotive experts who are passionate about creating an immersive and interactive metaverse experience for car enthusiasts."
            textLeft
          />
          <div className="h-[30px]" />
          <PageParagraph
            text="We believe that car ownership should be more than just a “possession” – it should be an experience. Something that can be enjoyed by everyone, regardless of location or circumstance."
            textLeft
          />
          <div className="h-[30px]" />
          <PageParagraph
            text="This is why we created EVOLVERSE – a metaverse where car and garage ownership is taken up another gear."
            textLeft
          />
        </>
      </PageHeader>
      <div className="flex justify-center items-center flex-col bg-black lg:pb-[200px]">
        <div className="mx-[20px] md:w-2/3 xl:w-1/2 lg:mx-auto lg:py-[30px]">
          {members.map((member) => {
            return <TeamMemberItem item={member} key={member.id} />;
          })}
        </div>
        <PageSubtitle text="Our Advisors" />
        <div className="mx-[20px] lg:flex-wrap md:w-2/3 lg:mx-[166.7px] lg:py-[60px] flex-col lg:flex-row flex align-start gap-5 xl:gap-7 justify-center">
          {advisors.map((member) => {
            return (
              <div className="lg:w-[30%]">
                <TeamAdvisorItem item={member} key={member.id} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
