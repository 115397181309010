import PageDescription from '@/components/common/pageDescription';
import PageHeader from '@/components/common/pageHeader';
import RoadmapDetails from '@/components/roadMap/details';

export default function Roadmap() {
  return (
    <div>
      <div className="flex justify-center items-center flex-col bg-black pt-[45px] py-[180px]">
        <PageHeader title="ROADMAP">
          <PageDescription text="The engine is running. Jump in, and let's go full-throttle towards the metaverse of car ownership." />
        </PageHeader>
        <div className="h-[40px]" />
        <RoadmapDetails />
      </div>
    </div>
  );
}
