import Avator from '@/components/common/avator';

export interface TeamAdvisor {
  id: string;
  name: string;
  profile_image: string;
  description: string;
}

export function TeamAdvisorItem({ item }: { item: TeamAdvisor }) {
  const { name, description, profile_image } = item;
  return (
    <div className="text-white flex lg:flex-col justify-items-center lg:py-[34.4px] grow basis-0 gap-3">
      <div className="flex flex-col items-center my-2">
        <Avator profileImage={profile_image} />
        <div className="lg:min-w-[216px] sm:min-w-[160px] text-[10px] sm:text-[18px] lg:text-[30px] text-white text-center">
          {name}
        </div>
      </div>
      <div
        className="text-left text-[12px] sm:text-[15px] lg:text-[17.3px] lg:leading-[34.5px] flex flex-col my-3 sm:my-7"
        dangerouslySetInnerHTML={{
          __html: `${description}`,
        }}
      />
    </div>
  );
}
