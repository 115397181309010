import { useEffect, useState } from 'react';

import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '@/firebase';
import PageTitle from '@/components/common/pageTitle';
import PageDescription from '@/components/common/pageDescription';

interface Brand {
  id: string;
  name: string;
  priority: number;
  image_url?: string;
  link: string;
}

export default function Partners() {
  const [brands, setBrands] = useState(new Array<Brand>());
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'brands'), orderBy('priority'));
      const querySnapshot = await getDocs(q);

      const results = new Array<any>();
      querySnapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        } as Brand);
      });
      setBrands(results);
    };
    fetchData();
  }, []);

  return (
    <div className="w-screen flex justify-center items-center flex-col bg-black pb-10 lg:pb-[150px]">
      <div className="bg-center bg-cover bg-[url('https://storage.googleapis.com/evolverse.appspot.com/website_cdn/images/evolverse_partnerships_no_logo_v01.jpg')] w-screen aspect-[1920/700] flex justify-center items-center flex-col">
        <div className="h-[150px]" />
        <PageTitle text="PARTNERS" />
        <PageDescription text="Whom join force EVOLVERSE on the Web3 road." />
      </div>
      <div className="text-white container flex flex-wrap my-5">
        {brands.map((brand: Brand) => {
          return (
            <div
              className="basis-1/3  text-[20px] lg:text-[30px] text-center flex flex-col justify-center items-center"
              key={brand.id}
            >
              <a href={brand.link} target="_blank" rel="noreferrer">
                <div
                  className={`w-[100px] lg:w-[200px] aspect-square bg-contain bg-no-repeat bg-center`}
                  style={{
                    backgroundImage: brand.image_url
                      ? `url('${brand.image_url}')`
                      : '',
                  }}
                />
                {!brand.image_url ? <span>{brand.name}</span> : <></>}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
