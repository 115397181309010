import { NavLink } from 'react-router-dom';

export default function FooterLink({
  name,
  href,
  external = false,
}: {
  name: string;
  href?: string;
  external?: Boolean;
}) {
  return (
    <div className="text-[14px] lg:text-[19px] pb-[16px] lg:pb-[26px] font-['D_Din_Expand']">
      {!external ? (
        <NavLink to={href || ''}>{name}</NavLink>
      ) : (
        <a href={href} target="_blank" rel="noreferrer">
          {name}
        </a>
      )}
    </div>
  );
}
