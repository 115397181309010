import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function Nft() {
  return (
    <div className="w-screen aspect-[1392/783] bg-[url('https://storage.googleapis.com/evolverse.appspot.com/website_cdn/images/bty_garage_low_v01.jpg')] flex flex-col justify-center bg-black bg-cover bg-center relative">
      {/* <div className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-30" /> */}
      <div className="grow" />
      <div className="z-9 flex flex-col items-center lg:mb-20">
        <PageSubtitle text="IMPORT YOUR NFT COLLECTION" />
        <PageDescription text="Ramp up the wow-factor of your garage by importing your own NFT collection." />
      </div>
    </div>
  );
}
