import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: 'AIzaSyD_8Uw8YtWWhgX3fvd8erR5kNs-0Rc-Mjw',
  authDomain: 'evolverse.firebaseapp.com',
  projectId: 'evolverse',
  storageBucket: 'evolverse.appspot.com',
  messagingSenderId: '506057661758',
  appId: '1:506057661758:web:9975cd97cbc63df372e06f',
  measurementId: 'G-KSW4RKSBX3',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
