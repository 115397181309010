import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

export interface Faq {
  id: string;
  question: string;
  answer: string;
}

export function FaqItem({ item }: { item: Faq }) {
  return (
    <div className="border-solid border-b-2 border-red">
      <Accordion square disableGutters>
        <AccordionSummary
          sx={{
            backgroundColor: 'black',
            color: 'white',
            fontSize: '24.2px',
          }}
          expandIcon={
            <ExpandCircleDownIcon htmlColor="white" fontSize="large" />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontFamily: "'D Din Expanded', sans-serif",
              fontSize: '24.2px',
              textAlign: 'left',
            }}
          >
            {item.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: 'black',
            color: 'white',
            textAlign: 'left',
            fontSize: '15.5px',
            lineHeight: '1.44',
            ul: {
              listStyleType: 'disc',
              listStylePosition: 'inside',
            },
            a: {
              color: '#f90071',
            },
          }}
          dangerouslySetInnerHTML={{ __html: item.answer }}
        ></AccordionDetails>
      </Accordion>
    </div>
  );
}
