import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import SectionTitle from '@/components/common/sectionTitle';

export interface Content {
  content: string;
  title: string;
}

export function ContentItem({ contentKey }: { contentKey: string }) {
  const [content, setContent] = useState({} as Content);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'contents', contentKey);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data() as Content);
        console.log('Document data:', docSnap.data());
      }
    };
    fetchData();
  }, [contentKey]);

  return (
    <div className="bg-black text-white">
      <div className="container mx-auto bg-black text-white">
        <SectionTitle text={content.title} />
        <div
          className="text-left text-white"
          dangerouslySetInnerHTML={{ __html: content.content }}
        />
      </div>
    </div>
  );
}
