import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function PhysicalSouvenirs() {
  return (
    <div className="w-screen aspect-[1660/640] flex items-center  bg-black bg-cover bg-center overflow-hidden">
      <div className="grow flex flex-col items-center pl-[10%]">
        <PageSubtitle text="PHYSICAL SOUVENIRS" />
        <PageDescription text="Bring EVOLVERSE to the real world. Bespoke souvenirs will be gifted to members, with delivery directly to your door." />
      </div>
      <div className="w-[53%]">
        <div className="flex flex-row justify-start">
          <div className="basis-1/2 aspect-[800/667] bg-[url('https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fgarage%2Fsouvenir.jpeg?alt=media&token=8fd0c8ac-8921-438f-b13a-4069daf720b3')] bg-center bg-cover ml-[5%]" />
        </div>
      </div>
    </div>
  );
}
