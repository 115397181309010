import React from 'react';
import './App.css';
import { Outlet } from 'react-router-dom';
// import Modal from 'react-modal';

import Navbar from './components/navbar';
import Footer from './components/footer';
// import PageSubtitle from '@/components/common/pageSubtitle';

// import logo from './Evolverse.png';

// const customStyles = {
//   content: {
//     top: '0',
//     left: '0',
//     right: '0',
//     bottom: '0',
//     padding: '0',
//   },
// };

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header sm:w-screen">
//         <img src={logo} className=" w-4/6 my-24" alt="logo" />
//         <p className="text-xl sm:text-2xl lg:text-3xl xl:text-4xl uppercase">
//           Coming Soon
//         </p>
//       </header>
//     </div>
//   );
// }

function App() {
  return (
    <>
      <Navbar />
      <main className="relative bg-black">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default App;
