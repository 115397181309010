import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function PriorityMinting() {
  return (
    <div className="w-screen aspect-[1660/632] flex items-center bg-black bg-cover bg-center">
      <div className="grow flex flex-col items-center">
        <PageSubtitle text="PRIORITY OF" />
        <PageSubtitle text="MINTING" />
        <PageDescription text="Exclusive priority for all <br>upcoming pre-minting events." />
      </div>
      <div className="w-[60%] border border-white p-[5px]">
        <div className="flex">
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fmembership%2Fchapter01_collection_0001_ar_17x11_blue_v10.jpg?alt=media&token=e84f4116-4754-4b9d-853f-bccc5eecc7c7"
              alt=""
            />
          </div>
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fmembership%2Fchapter01_collection_0011_ar_17x11_yellow_v09.jpg?alt=media&token=c8a3e0d5-89e1-4dbc-80c8-0348403fe1a3"
              alt=""
            />
          </div>
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fmembership%2Fchapter01_collection_0012_ar_17x11_white_v09.jpg?alt=media&token=a16b0d50-7f2b-48df-96ee-38ea4308e941"
              alt=""
            />
          </div>
        </div>
        <div className="flex">
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fmembership%2Fchapter01_collection_0013_ar_17x11_silver_v09.jpg?alt=media&token=d19f917c-c8a4-486d-b9ca-97fdf960d0c2"
              alt=""
            />
          </div>
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fmembership%2Fchapter01_collection_0014_ar_17x11_red_v09.jpg?alt=media&token=6681816b-59f4-463e-9965-2928762e0342"
              alt=""
            />
          </div>
          <div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fmembership%2Fchapter01_collection_0015_ar_17x11_blue_v09.jpg?alt=media&token=7802a543-3d35-47ea-81be-c41c5681b90c"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
