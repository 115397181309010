import HomeGarage from '@/pages/home/garage';
import HomeMembership from '@/pages/home/membership';
import HomeTeam from '@/pages/home/team';
import HomeRoadmap from '@/pages/home/roadmap';
import HomePartners from '@/pages/home/partners';
import HomeFaqs from '@/pages/home/faqs';

import PageTitle from '@/components/common/pageTitle';
import PageDescription from '@/components/common/pageDescription';

export default function Home() {
  return (
    <div>
      <div className="w-screen aspect-[1920/1080] flex flex-col justify-center items-center bg-black bg-[url('https://storage.googleapis.com/evolverse.appspot.com/website_cdn/images/thedriver_evolverse_frontpage_fire_v03.jpg')] bg-cover bg-center">
        <div className="flex-1" />
        <div className="flex-1 w-screen flex justify-center items-center">
          <div className="grow" />
          <div className="w-[60%] flex flex-col justify-center items-center">
            <PageTitle text="DRIVE INTO THE<br> EVOLVERSE" />
            <div className="h-3 md:h-5 lg:h-7" />
            <PageDescription text="THE FUTURE OF CAR OWNERSHIP. NOW." />
            <div className="lg:h-[100px] xl:h-[200px]" />
          </div>
        </div>
      </div>
      <HomeGarage />
      <HomeMembership />
      <HomeTeam />
      <HomeRoadmap />
      <HomePartners />
      <HomeFaqs />
    </div>
  );
}
