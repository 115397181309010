import { useState, useEffect } from 'react';

import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '@/firebase';

import { Faq } from '@/components/faq/item';
import { FaqList } from '@/components/faq/list';
import AppButton from '@/components/common/appButton';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function Faqs() {
  const [faqs, setFaqs] = useState(new Array<Faq>());
  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, 'faqs'),
        where('featured_at_home', '==', true)
      );
      const querySnapshot = await getDocs(q);

      const results = new Array<any>();
      querySnapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        } as Faq);
      });
      setFaqs(results);
    };
    fetchData();
  }, []);

  return (
    <div className="w-screen flex flex-col justify-center items-center bg-black py-20">
      <PageSubtitle text="FAQs" />
      <div className="h-3 md:h-5 lg:h-7" />
      <div className="container mx-auto">
        <FaqList items={faqs} />
      </div>
      <div className="h-4 md:h-10" />
      <AppButton text="See all FAQs" href="/faqs" />
    </div>
  );
}
