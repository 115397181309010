export interface Garage {
  image: string;
  name: string;
}

export function InteractiveGarageItem({
  item,
  onClick,
}: {
  item: Garage;
  onClick: Function;
}) {
  const onGarageClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <>
      <div
        className="hidden lg:block border lg:border-0 border-white mb-4 lg:mb-0 cursor-zoom-in"
        onClick={onGarageClick}
      >
        <div className="lg:border border-white">
          <img src={item.image} alt={`${item.name} Garage`} />
        </div>
        <div className="h-[20px] lg:h-[40px]" />
        <div className="text-white text-[20px] lg:text-[30px] text-center">
          {item.name}
        </div>
      </div>
      <div className="lg:hidden border lg:border-0 border-white mb-4 lg:mb-0">
        <div className="lg:border border-white">
          <img src={item.image} alt={`${item.name} Garage`} />
        </div>
        <div className="h-[20px] lg:h-[40px]" />
        <div className="text-white text-[20px] lg:text-[30px] text-center">
          {item.name}
        </div>
      </div>
    </>
  );
}
