import React from 'react';

export default function GoGarageButton() {
  const text = 'Enter The Meta Garage';
  const href = 'https://evolverse-unity.web.app/';

  return (
    <button
      type="button"
      className="bg-gradient-to-br from-[transparent] via-[transparent] to-[transparent] bg-center bg-cover bg-no-repeat rounded-full h-[58px] px-5 cursor-auto"
    >
    </button>
  );

  return (
    <button
      type="button"
      className="bg-gradient-to-br from-[#AF40FF] via-[#5B42F3] to-[#00DDEB] bg-center bg-cover bg-no-repeat rounded-full h-[58px] px-5 hover:from-[#bf40ff] hover:via-[#7942f3] hover:to-[#00b6eb]"
    >
      <a
        href={href || ''}
        target="_blank"
        rel="noreferrer"
        className="text-white text-lg font-semibold font-['D_Din_Expand']"
      >
        {text}
      </a>
    </button>
  );
}
