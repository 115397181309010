import { Faq, FaqItem } from '@/components/faq/item';

export function FaqList({ items }: { items: Array<Faq> }) {
  return (
    <div className="lg:w-[868px] mx-auto">
      {items.map((faq) => {
        return <FaqItem key={faq.id} item={faq} />;
      })}
    </div>
  );
}
