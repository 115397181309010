export default function Avator({ profileImage }: { profileImage: string }) {
  return (
    <div className="w-[calc(100vw*0.14)] aspect-square rounded-full border border-white flex flex-col justify-center items-center">
      <div
        className="w-[calc(100vw*0.13)] aspect-square rounded-full bg-cover bg-center"
        style={{ backgroundImage: `url(${profileImage})` }}
      />
    </div>
  );
}
