import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/index.css';
import '@/i18n';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from '@/App';
import reportWebVitals from '@/reportWebVitals';
import { ScrollToTop } from '@/components/common/scrollToTop';

import Home from '@/routes/home';
import Garage from '@/routes/garage';
import Membership from '@/routes/membership';
import Team from '@/routes/team';
import Roadmap from '@/routes/roadmap';
import Partners from '@/routes/partners';
import Faqs from '@/routes/faqs';
import OfficialLinks from '@/routes/officialLinks';
import Terms from '@/routes/terms';
import PrivacyPolicy from '@/routes/privacy';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="garage" element={<Garage />} />
          <Route path="membership" element={<Membership />} />
          <Route path="team" element={<Team />} />
          <Route path="roadmap" element={<Roadmap />} />
          <Route path="partners" element={<Partners />} />
          <Route path="faqs" element={<Faqs />} />
          {/* <Route path="official_links" element={<OfficialLinks />} /> */}
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
