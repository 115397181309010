import { useState, useEffect } from 'react';

import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '@/firebase';

import { Faq } from '@/components/faq/item';
import { FaqList } from '@/components/faq/list';
import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';
import PageHeader from '@/components/common/pageHeader';

export default function Faqs() {
  const [faqs, setFaqs] = useState(new Array<Faq>());
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'faqs'), orderBy('priority'));
      const querySnapshot = await getDocs(q);

      const results = new Array<any>();
      querySnapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        } as Faq);
      });
      setFaqs(results);
    };
    fetchData();
  }, []);
  return (
    <div className="flex justify-center items-center flex-col bg-black pt-[45px] py-[180px]">
      <PageHeader title="FAQs">
        <PageDescription text="Getting Started" />
      </PageHeader>
      <div className="w-screen bg-black pt-[53px] pb-[128px] flex flex-col items-center">
        <div className="container mx-auto">
          <FaqList items={faqs} />
        </div>
      </div>
    </div>
  );
}
