import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { NavLink } from 'react-router-dom';

import HeaderLink, { HeaderNavigationItem } from '@/components/navbar/link';

const navigation: Array<HeaderNavigationItem> = [
  { name: 'Garage', href: '/garage', current: true },
  { name: 'Event', href: 'https://sema2022.evolverse.io', current: false },
  { name: 'Team', href: '/team', current: false },
  { name: 'Roadmap', href: '/roadmap', current: false },
  { name: 'Partners', href: '/partners', current: false },
  { name: 'FAQ', href: '/faqs', current: false },
  // { name: 'Official Links', href: 'official_links', current: false },
];

export default function Navbar() {
  return (
    <Disclosure as="nav" className="sticky top-0 bg-black z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <NavLink to={'/'}>
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block h-8 w-auto"
                      src="https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2FHome.png?alt=media&token=3048bb89-6e38-4c1c-b321-38f01f4e6d17"
                      alt="Evolverse"
                    />
                  </div>
                </NavLink>
                <div className="flex-1" />
                <div className="hidden lg:block sm:ml-6">
                  <nav className="flex space-x-4">
                    {navigation.map((item) => (
                      <HeaderLink key={item.name} item={item} />
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 absolute bg-black w-[100%]">
              {navigation.map((item) => (
                <HeaderLink key={item.name} item={item} isMobile />
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
