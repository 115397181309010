import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function Membership() {
  return (
    <div className="w-screen aspect-[1920/1080] flex flex-col justify-center items-center bg-black bg-[url('https://storage.googleapis.com/evolverse.appspot.com/website_cdn/images/evolverse_membership.jpg')] bg-center bg-cover relative">
      <div className="flex-1" />
      <div className="flex-1 flex basis-0">
        <div className="flex-1" />
        <div className="flex-1 flex flex-col justify-center items-center z-9 mb-2">
          <div className="flex basis-0 w-full">
            <div className="grow-[2] justify-end items-end">
              <PageSubtitle text="MEMBERSHIP" />
            </div>
            <div className="grow-[3]"></div>
          </div>
          <div className="h-3 md:h-5 lg:h-7" />
          <PageDescription
            text="Cruise into the VIP lane with a membership that gives access to airdrops, garage connections, virtual events, and more!"
            textLeft
          />
        </div>
      </div>
    </div>
  );
}
