import React from 'react';
import { Disclosure } from '@headlessui/react';
import { NavLink } from 'react-router-dom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface HeaderNavigationItem {
  name: string;
  href: string;
  current: boolean;
}

export default function HeaderLink({
  item,
  isMobile = false,
}: {
  item: HeaderNavigationItem;
  isMobile?: boolean;
}) {
  const isExternalLink = item.href.startsWith('http');

  if (isMobile) {
    return (
      <Disclosure.Button
        as="a"
        href={item.href}
        className={classNames(
          item.current
            ? 'bg-gray-900 text-white'
            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
          'block px-3 py-2 rounded-md text-base font-medium'
        )}
        aria-current={item.current ? 'page' : undefined}
        target={isExternalLink ? '_blank' : undefined}
      >
        {item.name}
      </Disclosure.Button>
    );
  }

  return !isExternalLink ? (
    <NavLink
      to={item.href}
      className={({ isActive }) =>
        classNames(
          isActive
            ? 'underline underline-offset-8'
            : 'hover:underline underline-offset-8',
          'text-white px-3 py-2 rounded-md text-sm font-medium uppercase font-["D_Din_Expand"]'
        )
      }
    >
      {item.name}
    </NavLink>
  ) : (
    <a
      key={item.name}
      href={item.href}
      className={classNames(
        'hover:underline underline-offset-8',
        'text-white px-3 py-2 rounded-md text-sm font-medium uppercase font-["D_Din_Expand"]'
      )}
      target="_blank"
      rel="noreferrer"
    >
      {item.name}
    </a>
  );
}
