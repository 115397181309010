import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function Events() {
  return (
    <div className="w-screen aspect-[1920/930] bg-[url('https://storage.googleapis.com/evolverse.appspot.com/website_cdn/images/evolverse_event_v02.jpg')] flex flex-col justify-end pb-[5%] bg-black bg-cover bg-center relative">
      <div className="z-9 flex flex-col items-center">
        <PageSubtitle text="EVENTS" />
        <PageDescription text="Whip out your Garage Pass to receive the VIP treatment at all physical and metaverse events." />
      </div>
    </div>
  );
}
