import PageSubtitle from './pageSubtitle';

export default function PageHeader({
  title,
  children,
}: {
  title: string;
  children?: JSX.Element;
}) {
  return (
    <div className="container mx-auto px-5 md:px-20">
      <PageSubtitle text={title} />
      <div className="h-3 md:h-5 lg:h-7" />
      {children}
    </div>
  );
}
