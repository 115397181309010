import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function BenefitTrading() {
  return (
    <div className="w-screen aspect-[1660/825] flex flex-col items-center bg-black bg-cover bg-center">
      <PageSubtitle text="BENEFITS OF TRADING NFT" />
      <PageDescription text="EVOLVERSE members trading on partner marketplace www.pixnana.io will enjoy <br>addition benefits and airdrops. Stay tuned the crossover details." />
      <div className="bg-[url('https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fmembership%2Fpixnana_website_page_landing_v16-02_wallet_connected.jpg?alt=media&token=38ed0ad6-1533-4fe0-877a-94abb7a1f166')] aspect-[1077/535] bg-center bg-cover w-[65%]" />
    </div>
  );
}
