import { useState, useEffect } from 'react';
import PageSubtitle from '@/components/common/pageSubtitle';
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  where,
} from 'firebase/firestore';
import { db } from '@/firebase';
import { TeamMember } from '@/components/team/memberItem';
import Avator from '@/components/common/avator';
import PageDescription from '@/components/common/pageDescription';
import { TeamAdvisor } from '@/components/team/advisorItem';

export default function Team() {
  const [members, setMembers] = useState(new Array<any>());
  const [advisors, setAdvisors] = useState(new Array<any>());
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, 'members'), orderBy('priority'), limit(3));
      const querySnapshot = await getDocs(q);

      const results = new Array<TeamMember>();
      querySnapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        } as TeamMember);
      });
      setMembers(results);

      const q2 = query(
        collection(db, 'advisors'),
        where('is_hidden', '==', false),
        orderBy('priority'),
        limit(3)
      );
      const querySnapshot2 = await getDocs(q2);

      const results2 = new Array<TeamAdvisor>();
      querySnapshot2.forEach((doc) => {
        results2.push({
          id: doc.id,
          ...doc.data(),
        } as TeamAdvisor);
      });
      setAdvisors(results2);
    };
    fetchData();
  }, []);
  return (
    <div className="py-10">
      <div className="w-screen aspect-[1660/748] flex flex-col justify-center items-center bg-black bg-center bg-cover px-[15.8%] py-10">
        <PageSubtitle text="TEAM" />
        <div className="h-3 md:h-5 lg:h-7" />
        <PageDescription text="Powered by a team of car nuts, tech gurus, and creative wizards, we're bringing the future of car ownership to life." />
        <div className="w-[100%] flex gap-[18px] mt-[20px] lg:mt-[45px]">
          {members.map((member) => {
            return (
              <div
                className="w-[33%] flex flex-col justify-start items-center"
                key={member.id}
              >
                <Avator profileImage={member.profile_image} />
                <div className="text-[14px] sm:text-[18px] lg:text-[30px] text-white text-center">
                  {member.name}
                </div>
                <div className="text-[8px] sm:text-[12px] lg:text-[30px] text-white text-center">
                  {member.position}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-screen aspect-[1660/748] flex flex-col justify-center items-center bg-black bg-center bg-cover px-[15.8%] py-10">
        <PageSubtitle text="ADVISORS" />
        <div className="w-[100%] flex justify-center gap-[18px] mt-[20px] lg:mt-[45px]">
          {advisors.map((member) => {
            return (
              <div
                className="w-[33%] flex flex-col justify-start items-center"
                key={member.id}
              >
                <Avator profileImage={member.profile_image} />
                <div className="text-[14px] sm:text-[18px] lg:text-[30px] text-white text-center">
                  {member.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
