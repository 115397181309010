import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';

export default function Interconnection() {
  return (
    <div className="w-screen aspect-[1660/847] bg-[url('https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/website%2Fimages%2Fnismo_thedriver_concept_reddoor_a_v06.jpg?alt=media&token=528d9003-f3d7-460e-b3e9-aa0415594b8a')] flex flex-col justify-center bg-black bg-cover bg-center relative">
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-30" />
      <div className="z-9 flex flex-col items-center">
        <PageSubtitle text="GARAGE INTERCONNECTION" />
        <PageDescription text="Connect, appreciate, exchange, and discuss your <br>passion with other garage enthusiasts." />
      </div>
    </div>
  );
}
