import PageDescription from '@/components/common/pageDescription';
import PageSubtitle from '@/components/common/pageSubtitle';
import RoadmapDetails from '@/components/roadMap/details';

export default function Roadmap() {
  return (
    <div className="w-screen aspect-[1660/1183] flex flex-col justify-center items-center bg-black bg-center bg-cover">
      <PageSubtitle text="ROADMAP" />
      <div className="h-3 md:h-5 lg:h-7" />
      <PageDescription text="The engine is running. Jump in, and let's go full-throttle towards the metaverse of car ownership." />
      <div className="h-[40px]" />
      <RoadmapDetails />
    </div>
  );
}
