export default function PageDescription({
  text,
  textLeft = false,
}: {
  text: string;
  textLeft?: boolean;
}) {
  return (
    <div
      className={`text-[13px] lg:text-[27.6px] leading-[16px] lg:leading-[36px] text-white ${
        textLeft ? 'text-left' : 'text-center'
      }`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}
