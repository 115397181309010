import MembershipGarage from '@/pages/membership/garage';
import MembershipAirdrop from '@/pages/membership/airdrop';
import MembershipNft from '@/pages/membership/nft';
import MembershipInterconnection from '@/pages/membership/interconnection';
import MembershipPriorityMinting from '@/pages/membership/priorityMinting';
import MembershipEvents from '@/pages/membership/events';
import MembershipPhysicalSouvenirs from '@/pages/membership/physicalSouvenirs';
import MembershipBenefitTrading from '@/pages/membership/benefitTrading';

export default function Membership() {
  return (
    <div>
      <MembershipGarage />
      <MembershipAirdrop />
      <MembershipNft />
      <MembershipInterconnection />
      <MembershipPriorityMinting />
      <MembershipEvents />
      <MembershipPhysicalSouvenirs />
      <MembershipBenefitTrading />
    </div>
  );
}
